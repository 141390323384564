import { createSlice } from "@reduxjs/toolkit";
import isEmpty from "../../utils/is-empty";
import setAuthToken from "../../utils/setAuthToken";
import authAPI from "../../axios-setup/auth-service";

const initialState = {
  token: typeof window !== "undefined" ? localStorage.getItem("token") : null,
  isAuthenticated: false,
  user: null,
  error: null,
  loading: false,
  otpSent: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.isAuthenticated = !isEmpty(action.payload);
      state.user = action.payload;
      state.otpSent = false;
    },
    setToken(state, action) {
      state.isAuthenticated = !isEmpty(action.payload);
      state.token = action.payload;
    },
    authFailure(state, action) {
      state.token = null;
      state.isAuthenticated = false;
      state.user = null;

      state.error = action.payload ? action.payload : "Could not connect";
    },
    setOtpSent(state) {
      state.otpSent = true;
    },
    setLogout(state) {
      state.token = null;
      state.isAuthenticated = false;
      state.user = null;
      state.otpSent = false;
    },
    setLoading(state) {
      state.loading = true;
    },
    clearLoading(state) {
      state.loading = false;
    },
  },
});

export const {
  setCurrentUser,
  authFailure,
  setToken,
  setLogout,
  setLoading,
  clearLoading,
  setOtpSent,
} = authSlice.actions;

export default authSlice.reducer;

// thunks

//load user
export const loadUser = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await authAPI.get("/api/v1/auth/get-me");
    dispatch(clearLoading());
    dispatch(setCurrentUser(res.data.data));
  } catch (err) {
    dispatch(clearLoading());
    // dispatch(authFailure(err.response.data.error));
  }
};

//send otp
export const sendOTP = (phone, toast, setOtpSentComp) => async (dispatch) => {
  if (phone.length !== 10) {
    toast.error("Enter a valid 10 digit phone number!");
    return;
  }

  try {
    dispatch(setLoading());
    const res = await authAPI.post("/api/v1/auth/sendotp", {
      phoneNumber: phone.slice(-10),
      browserId: localStorage.getItem("FPJS").replace(/"/g, ""),
    });

    dispatch(clearLoading());
    if (res.data.success) {
      toast.success("OTP Sent SuccessFully");
      dispatch(setOtpSent());
      setOtpSentComp(true);
    } else {
      toast.error("Failed To Send OTP");
      setOtpSentComp(false);
    }
  } catch (err) {
    dispatch(clearLoading());
    setOtpSentComp(false);
    //dispatch(authFailure(err.response.data.error));
  }
};

//verify otp
export const verifyOTP =
  (formData, toast, afterFullSteps, setRegistered, setOtpVerified) =>
  async (dispatch) => {
    try {
      dispatch(setLoading());

      const res = await authAPI.post("/api/v1/auth/verifyotp", {
        phoneNumber: formData.phone,
        otp: formData.otp,
      });

      if (res.data.success) {
        setOtpVerified(true);
        toast.success("OTP Verified!");
        const token = res.data.data.authToken;

        dispatch(setToken(token));
        await setAuthToken(token);
        dispatch(loadUser());
        const registered = res.data.data.registered;

        setRegistered(registered);

        if (registered) {
          if (afterFullSteps) afterFullSteps();
        }
      } else {
        toast.error("Invalid OTP!");
      }

      dispatch(clearLoading());
    } catch (err) {
      dispatch(clearLoading());
      toast.error("Invalid OTP!");
    }
  };

//logout
export const logout = (toast, router) => async (dispatch) => {
  // router.push("/");
  delete authAPI.defaults.headers.common["Authorization"];
  localStorage.removeItem("token");
  dispatch(setLogout());
  toast.success("Logged out successfully");
};

//update user
export const updateUser = (formData, toast) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await authAPI.post("/api/v1/auth/update-me", formData);
    dispatch(clearLoading());
    toast.success("Profile Updated!");
    dispatch(setCurrentUser(res.data.data));
  } catch (err) {
    dispatch(clearLoading());
  }
};

//update user image
export const updateUserImage = (formData, toast) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await authAPI.post("/api/v1/auth/update-img", formData);
    dispatch(clearLoading());

    toast.success("Image Updated!");
    dispatch(setCurrentUser(res.data.data));
  } catch (err) {
    dispatch(clearLoading());
  }
};
